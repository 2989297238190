<template>
    <div class="user">
        <h5header :name="name" :right="false" />
        <div class="user-con">
            <component :is="navname"></component>
        </div>
    </div>
</template>

<script>
import AccountInfo from '@/componets/accountinfo.vue';
import Trade from '@/componets/trade.vue'
import Google from '@/componets/google.vue'
import unbindGoogle from '@/componets/unbindgoogle.vue';
import h5header from '@/componets/h5header.vue';
import TransferAccount from '@/componets/transferAccount.vue';
export default {
    components: {
        AccountInfo,
        Trade,
        Google,
        unbindGoogle,
        h5header,
        TransferAccount
    },
    data() {
        return {
            name: '',
            navname: '',
            bindGoogle: false
        }
    },
    watch: {
        $route: {
            // $route可以用引号，也可以不用引号  监听的对象
            handler(to, from) {
                if (to.query.id != undefined) {
                    this.bindGoogle = this.$store.state.userData.hasBindGoogleAuth
                    let id = this.$route.query.id;
                    if (id == 1) {
                        this.navname = 'AccountInfo';
                        this.name = this.$t('sign.desc41')
                    } else if (id == 2) {
                        this.navname = 'Trade';
                        this.name = this.$t('home.desc7')
                    } else if (id == 3 && this.bindGoogle) {
                        this.navname = 'unbindGoogle';
                        this.name = this.$t('home.desc33')
                    } else if(id == 99){
                        this.navname = 'TransferAccount';
                        this.name = this.$t('transferAccount.desc0')
                    }else{
                        this.navname = 'Google';
                        this.name = this.$t('home.desc22')
                    }
                    document.title = this.name;
                }
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        },
    },
    mounted() {
        // this.bindGoogle = this.$store.state.userData.hasBindGoogleAuth
        // let id=this.$route.query.id;
        // if(id==1){
        //     this.navname='AccountInfo';
        //     this.name=this.$t('sign.desc41')
        // }else if(id==2) {
        //     this.navname='Trade';
        //     this.name=this.$t('home.desc7')
        // }else if(id == 3 && this.bindGoogle){
        //     this.navname='unbindGoogle';
        //     this.name=this.$t('home.desc33')
        // }else{
        //     this.navname='Google';
        //     this.name=this.$t('home.desc22')
        // }
        // document.title=this.name;
    }
}
</script>

<style lang="less" scoped>
.user-con {
    padding: 56px 16px;
}
</style>